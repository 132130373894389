import { createRouter, createWebHistory } from 'vue-router'
// import LandingView from '../views/LandingView.vue'
// import PatientView from '../views/PatientView.vue'
// import PatientCreateView from '../views/PatientView/PatientCreateView.vue'
// import PatientDetailView from '../views/PatientView/PatientDetailView.vue'
import UserView from '../views/UserView.vue'

const routes = [
    {
        path: '/',
        name: 'landing',
        // component: LandingView,
        component: () => import(/* webpackChunkName: "about" */ '../views/LandingView.vue'),
        alias: '/home',
        // meta: { title: ['홈보드', '관찰'], tabs: ['홈보드', '관찰'], requiresAuth: true }
        meta: { title: ['관찰'], tabs: ['관찰'], requiresAuth: true }
    },
    {
        path: '/patient',
        name: 'patient',
        // component: PatientView,
        component: () => import(/* webpackChunkName: "about" */ '../views/PatientView.vue'),
        meta: { title: '환자', requiresAuth: true }
    },
    {
        path: '/patient/:id',
        name: 'patientdetail',
        // component: PatientDetailView,
        component: () => import(/* webpackChunkName: "about" */ '../views/PatientView/PatientDetailView.vue'),
        props: true,
        meta: { title: '환자 상세', requiresAuth: true }
    },
    {
        path: '/patient/create',
        name: 'patientcreate',
        // component: () => PatientCreateView,
        component: () => import(/* webpackChunkName: "about" */ '../views/PatientView/PatientCreateView.vue'),
        meta: { title: ['환자'], requiresAuth: true },
        props: { isEdit: false }
    },
    {
        path: '/patient/modify/:id',
        name: 'patientModify',
        // component: () => PatientCreateView,
        component: () => import(/* webpackChunkName: "about" */ '../views/PatientView/PatientCreateView.vue'),
        meta: { title: ['환자'], requiresAuth: true },
        props: route => ({ isEdit: true, id: route.params.id })
    },
    {
        path: '/user',
        name: 'user',
        component: () => UserView,
        meta: { title: ['사용자'], requiresAuth: true }
    },
    {
        path: '/consult',
        name: 'consult',
        component: () => import(/* webpackChunkName: "about" */ '../views/ConsultView.vue'),
        // meta: { title: '소통', requiresAuth: true }
        meta: { title: ['소통'], requiresAuth: true }
    },
    {
        path: '/device',
        name: 'device',
        component: () => import(/* webpackChunkName: "about" */ '../views/DeviceView.vue'),
        meta: { title: '기기관리', requiresAuth: true }
    },
    {
        path: '/reservation',
        name: 'reservation',
        component: () => import(/* webpackChunkName: "about" */ '../views/ReservationView.vue'),
        meta: { title: '예약', requiresAuth: true }
    },
    {
        path: '/setting',
        name: 'setting',
        component: () => import(/* webpackChunkName: "about" */ '../views/SettingView.vue'),
        meta: { title: '설정', requiresAuth: true }
    },
    {
        path: '/signin',
        name: 'signin',
        component: () => import(/* webpackChunkName: "about" */ '../views/Sign/LoginView.vue'),
        meta: { title: 'signin', requiresAuth: true }
    },
    {
        path: '/password/reset',
        name: 'passwordreset',
        component: () => import(/* webpackChunkName: "about" */ '../views/Sign/PasswordresetView.vue'),
        meta: { title: 'passwordreset', requiresAuth: true }
    },
    {
        path: '/emailcomplete',
        name: 'emailcomplete',
        component: () => import(/* webpackChunkName: "about" */ '../views/Sign/EmailcompleteView.vue'),
        meta: { title: 'emailcomplete', requiresAuth: true }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'My App';

    const isLoginPage = to.path === '/signin' || to.path === '/emailcomplete' || to.path === '/password/reset'
    const isAuthenticated = checkAuthentication();

    if(to.path == '/password/reset' || to.path == '/emailcomplete') {
        // 로그인 세션 삭제
        sessionStorage.removeItem('session');
        sessionStorage.setItem('session', JSON.stringify({isLogin: false}));

        // 토큰 쿠키 삭제
        deleteCookie("token");
    }

    if (!isLoginPage && !isAuthenticated) {
        // 인증이 필요한 페이지에 로그인되어 있지 않으면 로그인 페이지로 리다이렉트
        next({ path : '/signin' });

    } else if(to.path == '/signin' && isAuthenticated) {
        // 로그인 세션이 존재하는데 로그인 페이지로 이동한다면
        next({ path : '/' });

    } else {
        // 인증이 필요하지 않거나 인증된 경우 정상적으로 진행
        next();
    }
});

// 쿠키 체크
function checkCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // 쿠키의 이름으로 시작하는지 확인
        if (cookie.startsWith(`${name}=`)) {
            return true; // 해당 이름의 쿠키(토큰)이 존재함
        }
    }
    return false; // 해당 이름의 쿠키(토큰)이 존재하지 않음
}

function checkAuthentication() {
    // 세션 여부 확인 로직
    if(sessionStorage.getItem('session') && checkCookie("token")) {
        const loginExpirationTime = JSON.parse(sessionStorage.getItem('session')).expiresAt;
        // const expiryDate = new Date(loginExpirationTime);
        // const currentDate = new Date();

        // console.log(loginExpirationTime);

        // if (currentDate > expiryDate) {
        //     alert('토큰 시간 만료됨');
        //     sessionStorage.removeItem('session');
        //     deleteCookie("token");
        //     return false;

        // } else {
        //     return true;
        // }

        const expiryDate = new Date(loginExpirationTime);
        const currentDate = new Date();

        if (currentDate > expiryDate) {
            // alert('토큰 시간 만료됨');
            sessionStorage.removeItem('session');
            // localStorage.removeItem('takeMedication');
            // localStorage.removeItem('patientInfo');
            // localStorage.removeItem('getHomeboardParams');
            // localStorage.removeItem('patientDetailTempTimeChartData');
            // localStorage.removeItem('patientDetailTempDailyChartData');
            // localStorage.removeItem('patientModifyInfo');
            // localStorage.removeItem('newTakeMedicationitems');
            localStorage.clear();
            deleteCookie("token");
            return false;
        } else {
            return true;
        }




        // const isLogin = JSON.parse(sessionStorage.getItem('session')).isLogin;
        // const loginExpirationTime = JSON.parse(sessionStorage.getItem('session')).expiresAt;

        // // 세션 스토리지에 사용자 정보가 있고 유효 기간이 지나지 않았으면 인증된 것으로 간주
        // return isLogin && loginExpirationTime && Date.now() < loginExpirationTime;


        // if(isLogin && loginExpirationTime) {
        //     console.log(Date.now());
        //     console.log(loginExpirationTime);
        //     if(Date.now() < loginExpirationTime) {
        //         // 만료기간 5분 이내일 경우, 토큰 갱신하여 세션 저장
        //         console.log('여기1');
        //         if(isTokenNearExpiration()) {
        //             console.log('여기3');
        //             // 토큰 갱신 API
        //             // refreshToken();
                    
        //         }
        //     }
        // }

    }
}

// 쿠키 삭제
function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    return true;
}

// // 토큰 갱신 API
// async function refreshToken() {
//     try {
//         const usedToken = JSON.parse(sessionStorage.getItem('session')).token;
//         const api = `http://192.168.0.169:8080/api/web/authentications/${usedToken}`;
//         const response = await axios.push(api);
//         sessionStorage.setItem('session', JSON.stringify({token: response}));

//     } catch (error) {
//         console.log(error);
//         // alert('잠시후 다시 시도해주세요.');
//         // if(error.response.status == 401) {
//         //     // 세션 삭제
//         //     sessionStorage.removeItem('session');
//         //     sessionStorage.setItem('session', JSON.stringify({isLogin: false}));
//         //     // 로그인 페이지로 이동
//         //     console.log('로그인 페이지');
//         // }
//     }
// }

// 
// function getTokenRemainingTime() {
//     const expirationTime = JSON.parse(sessionStorage.getItem('session')).expiresAt;
//     const currentTime = Date.now();
//     return expirationTime - currentTime; // 남은 시간(밀리초)
// }

// // 토큰이 일정 시간 이내로 남았는지 확인하는 함수
// function isTokenNearExpiration() {
//     const remainingTime = getTokenRemainingTime();
//     const threshold = 59 * 60 * 1000; // 5분을 밀리초로 변환
//     return remainingTime < threshold; // 만료까지 시간이 5분 이내라면 true 반환
// }

export default router
