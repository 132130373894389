<style scoped>
.wrap-floating-btn {
    position: absolute;
    right: 64px;
    bottom: 64px;
    cursor: pointer;
}

/* message-modal */
.header-modal {
    z-index: 100;
    color: #002244;
}
.header-modal .modal-container {
    background-color: #fff;
}
.header-modal .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-modal .modal-header b {
    font-size: 24px;
    margin-left: 16px;
}
.modal-body {
    position: relative;
}
.footer-call-modal {
    
}
.footer-call-modal .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}
.footer-call-modal .wrap-search {
    position: relative;
    margin-bottom: 20px;
}
.footer-call-modal .wrap-search-icon {
    position: absolute;
    top: 9px;
    left: 7px;
    font-size: 17px;
    color: #888888;
}
.footer-call-modal .wrap-search input {
    padding-left: 30px;
    background-color: #eee;
    border-radius: 8px;
    border: none;
}
.footer-call-modal .wrap-search input::placeholder {
    color: #888888;
}
.footer-call-modal input[type="text"]:focus,
.footer-call-modal .message-content textarea:focus {
    outline: none;
}
.footer-call-modal .message-content textarea {
    background-color: #EEEEEE;
    border-radius: 8px;
    resize: none;
    width: 100%;
    padding: 16px;
    font-size: 14px;
    margin-bottom: 40px;
}
.footer-call-modal .message-content textarea::placeholder {
    color: #888888;
}
.footer-call-modal .wrap-footer-btn {
    text-align: right;
}
.keywordList {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #DDDDDD;
    width: 100%;
    padding: 6px 16px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 8px;
    margin-top: -14px;
}
.keywordList li {
    padding: 10px 0; 
    cursor: pointer;
}
</style>

<script>
import ModalComponent from '@/components/modal/ModalComponent.vue';
import DefaultInputComponent from '@/components/form/input/DefaultInputComponent.vue';
import { ref } from 'vue';
import { errNotify, notify } from '@/plugins/toastify';

export default {
    components: {
        ModalComponent,
        DefaultInputComponent
    },
    data() {
        return {
            hover: false,

            // 모달
            callShowModal: false,
            keyword: '',
            keywordList: [],
            searchKeyword: '',
            patientInfo: '',
            patientId:'',
            consultCallContent: '',
        };
    },
    methods: {
        // 모달
        async callCloseModal(event){
            event.preventDefault();
            this.callShowModal = false;
            this.patientId = ''
            this.consultCallContent = ''
        },
        async consultCallSave(event) {
            event.preventDefault();

            const apiUrl = `/api/web/tel-discussions`;
            const requestData = {
                patientId: this.patientId,
                content: this.consultCallContent
            };
            if(this.patientId !== '') {
                if(this.consultCallContent !== '') {
                    try {
                        const response = await fetch(apiUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(requestData)
                        });

                        if(response.status == 200) {
                            notify('전화 상담을 저장하였습니다.');
                            this.patientId = ''
                            this.consultCallContent = ''
                        } else {
                            errNotify('전화 상담이 저장되지 않았습니다.');
                        }
                        this.callShowModal = false;
                    } catch (error) {
                        console.error('Error fetching API data:', error);
                    }
                } else {
                    errNotify('메모 내용을 입력해주세요.')
                }
            }else {
                errNotify('환자 정보를 입력해주세요.')
            }
        },

        // 전화 상담 환자 자동검색
        async autoSearch(event){
            const keyword = event.target.value;
            try {
                if(keyword.length !== 0 || event.keyCode === 'backspace'){
                    const response = await fetch(`/api/web/patients/search?keyword=${keyword}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    if (response.status === 200) {
                        const responseData = await response.json();
                        this.keywordList = responseData;
                        this.isSearch = true;
                    }else if(response.status === 422) {
                        this.isSearch = false
                    }else if(response.status === 404) {
                        alert('해당 환자 정보가 존재하지 않습니다.');
                    }
                }
                if(keyword.length === 0 || keyword.length === null || keyword === '' || this.keywordList.length === 0 || this.keywordList.length === null ) {
                    this.isSearch = false
                }
            } catch (error) {
                this.isSearch = false
                // TODO: 검색 결과 없을 시 오류 뱉음 
                // console.error('Error fetching API data:', error);
            }
        },

        // 전화 상담 > input에 선택한 환자 정보 출력
        async getPatientInfo(event) {
            this.isSearch = false
            this.patientInfo = event.target.textContent
            this.patientId = event.currentTarget.closest("[data-patientId]").getAttribute("data-patientId");
        },
    },
    setup() {
        const isSearch = ref(false)
        return {
            isSearch
        }
    },
};
</script>

<template>
    <div>
        <footer>
            <div class="wrap-floating-btn">
                <img 
                    :src="hover ? require('/src/assets/imgs/callIconHover.png') : require('/src/assets/imgs/callIcon.png')" 
                    alt="이미지"
                    @mouseover="hover = true"
                    @mouseleave="hover = false"
                    @click="callShowModal = true"
                > 
            </div>
            <!-- 전화상담 기록 모달 -->
            <ModalComponent class="header-modal footer-call-modal" :show-modal="callShowModal" modal-title="전화상담 기록 모달">
                <template v-slot:header>
                    <div class="modal-header">
                        <div class="flex align-center">
                            <img :src="require('/src/assets/imgs/call_modal_icon.png')" alt="환자상세변경이력이미지">
                            <b>전화상담 기록</b>
                        </div>
                        <img class="cursor-pointer" style="background-color: #fff; border-radius: 50%;" @click="callCloseModal" :src="require('/src/assets/imgs/profile_close_icon.png')" alt="취소이미지">
                    </div>
                </template>
                <template v-slot:body>
                    <div class="modal-body">
                        <div class="wrap-search">
                            <span class="wrap-search-icon"><i class="icon-search"></i></span>
                            <DefaultInputComponent class="wp-100" type="text" required :inputText="'환자검색'" @keyup="autoSearch($event)" :value="patientInfo" />
                        </div>
                        <ul v-show="isSearch" :class="['searchList', {'keywordList': isSearch}]">
                            <li v-for="(item, index) in keywordList" :key="index" :data-code="item.patientCode" :data-patientId="item.id" @click="getPatientInfo($event)">{{ item.name }} ({{ item.gender }}, {{ item.age }}) {{ item.patientCode }}</li>
                        </ul>
                        <div class="message-content">
                            <textarea name="" id="" cols="30" rows="10" placeholder="메모내용 입력" v-model="consultCallContent"></textarea>
                        </div>
                    </div>
                </template>
                <template v-slot:footer>
                    <div class="wrap-footer-btn">
                        <button class="" @click="consultCallSave">저장</button>
                    </div>
                </template>
            </ModalComponent>
        </footer>
    </div>
</template>
