<style scoped>
input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #0088FF;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
    background: url('/src/assets/imgs/check.png') no-repeat center center;
}
input[type="checkbox"]:checked {
    background-color: #0088FF;
}
label {
    margin-left: 10px;
    color: #002244;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}
input[type="checkbox"].disabled {
    border: 2px solid #CCCCCC;
}
input[type="checkbox"]:checked.disabled {
    background-color: #CCCCCC;
}
label.disabled {
    color: #CCCCCC;
}
</style>

<script>
import { ref } from 'vue';

export default {
    props: {
        modelValue: Boolean,
        label: String,
        // disabled: Boolean,
    },
    setup(props, { emit }) {
        const isChecked = ref(props.modelValue || false);
        const handleCheckboxChange = () => {
            emit('update:modelValue', isChecked.value);
        };

        return {
            isChecked,
            handleCheckboxChange
        };
    },
};
</script>

<template>
    <label class="custom-checkbox flex align-center">
        <input type="checkbox" v-model="isChecked" @change="handleCheckboxChange" :disabled="modelValue" />
        {{ label }}
    </label>
</template>
  