<style scoped>
header {
    padding: 0 20px 0 50px;
    width: calc(100% - 160px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
header h2 {
    border-bottom: 4px solid #004488;
    margin-top: -4px;
}
header .header-msg-btn {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
}
header .header-icon {
    position: relative;
}
header .header-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #EE4400;
    position: absolute;
    top: 2px;
    right: -2px;
}

/* modal */
.header-modal {
    z-index: 100;
    color: #002244;
}
.header-modal .modal-container {
    background-color: #fff;
}
.header-modal .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-modal .modal-header b {
    font-size: 24px;
    margin-left: 16px;
}
/* message-modal */
.header-message-modal {
    
}
.header-message-modal .message-content-inner-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}
.header-message-modal .wrap-target {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.header-message-modal .wrap-radio-btn {
    display: flex;
    align-items: center;
}
.header-message-modal .radio-btn {
    display: flex;
    align-items: center;
}
.header-message-modal .radio-btn input {
    margin-right: 12px;
    width: 20px;
}
.header-message-modal .radio-btn:first-child {
    margin-right: 32px;
}
.header-message-modal .wrap-target b {
    margin-right: 20px;
}
.header-message-modal .wrap-search {
    position: relative;
    margin-bottom: 20px;
}
.header-message-modal .wrap-search-icon {
    position: absolute;
    top: 9px;
    left: 7px;
    font-size: 17px;
    color: #888888;
}
.header-message-modal .wrap-search input {
    padding-left: 30px;
    background-color: #eee;
    border-radius: 8px;
    border: none;
}
.header-message-modal .wrap-search input::placeholder {
    color: #888888;
}
.header-message-modal input[type="text"]:focus,
.header-message-modal .message-content textarea:focus {
    outline: none;
}
.header-message-modal .message-content textarea {
    background-color: #EEEEEE;
    border-radius: 8px;
    resize: none;
    width: 100%;
    padding: 16px;
    font-size: 14px;
    margin-bottom: 64px;
}
.header-message-modal .message-content textarea::placeholder {
    color: #888888;
}
.header-message-modal .wrap-footer-btn {
    text-align: right;
}
/* alarm/noti modal */
.card-alarm {
    border-radius: 8px;
    background-color: #FFDDDD;
    margin-bottom: 4px;
}
.card-alarm:hover {
    background-color: #FFCCCC;
}
.card-alarm:last-child {
    margin-bottom: 0;
}
.card-alarm.card-noti {
    background-color: #fff;
    margin-bottom: 16px;
}
.card-alarm.card-noti .wrap-date {
    background-color: #EEEEEE;
    color: #002244;
    padding: 12px 8px;
    border-bottom: 1px solid #DDDDDD
}
.card-alarm.card-noti .inner-card-alarm {
    border-bottom: 1px solid #DDDDDD;
}
.card-alarm.card-noti .card-content {
    color: #0066BB;
    text-decoration: underline;
    margin-right: 4px;
}
.card-alarm.card-noti .card-noti-kind {
    color: #0066BB;   
}
.alarm-none {
    background-color: #DDDDDD;
    border: 1px solid #DDDDDD;
    color: #888888;
    padding: 24px 0;
    border-radius: 8px;
    text-align: center;
}
.noti-none {
    background-color: #EEEEEE;
    border: 1px solid #DDDDDD;
    color: #AAAAAA;
    padding: 24px 0;
    border-radius: 8px;
    text-align: center;
}
.keywordList {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #DDDDDD;
    width: 100%;
    padding: 6px 16px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 8px;
    margin-top: -14px;
}
.keywordList li {
    padding: 10px 0; 
    cursor: pointer;
}
</style>

<script>
import ModalComponent from '@/components/modal/ModalComponent.vue';
import ModalButtonComponent from '@/components/button/ModalButtonComponent2.vue';
// import SquareCheckboxInputComponent from '@/components/form/input/SquareCheckboxInputComponent.vue';
import DefaultInputComponent from '@/components/form/input/DefaultInputComponent.vue';
import CheckboxInputComponent from '@/components/form/input/CheckboxInputComponent.vue';
import AlarmCardComponent from '@/components/card/AlarmCardComponent.vue';

import { ref } from 'vue';
import { errNotify, notify } from '@/plugins/toastify';

export default {
    name: 'TabComponent',
    components: {
        ModalComponent,
        ModalButtonComponent,
        // SquareCheckboxInputComponent,
        DefaultInputComponent,
        CheckboxInputComponent,
        AlarmCardComponent,
    },
    props: {
        // 랜딩 페이지가 이 이벤트를 리스닝할 수 있도록 설정
        onUpdateAlarmData: {
            type: Function,
            default: () => {}
        },
        // 랜딩 페이지가 이 이벤트를 리스닝할 수 있도록 설정
        onUpdateNotiData: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            messageShowModal: false,
            alarmShowModal: false,
            notiShowModal: false,
            options: ['환자', '의료진'],
            selectedOption: '환자',
            selectedIndex: -1,
            warningData: [],
            warningRowsData: [],
            infoData: [],
            infoRowsNotiData: [],
            newInfoTotalData: null,
            newWarningTotalData: null,
            keyword: '',
            keywordList: [],
            searchKeyword: '',
            patientInfo: '',
            patientId:'',
            messageContent: '',

            // socketAlarmData: [], // 웹소켓 알림 데이터 배열

            // 알람 소켓
            hasNewAlarms: false, // 알람 발생 여부를 저장하는 변수
            alarmSocket: null,
            // 알림 소켓
            hasNewNotifications: false, // 알람 발생 여부를 저장하는 변수
            notiSocket: null,

            

            // 알람 리스트
            alarmHeaderOffset: 1,
            alarmHeaderLimit: 10,
            // 알림 리스트
            infoHeaderOffset: 1,
            infoHeaderLimit: 10,
        };
    },
    created() {
        if (this.alarmSocket != null) {
            this.alarmSocket.close();
        }

        if (this.notiSocket != null) {
            this.notiSocket.close();
        }
    },
    // // 컴포넌트가 언마운트될 때 소켓 연결 종료
    // unmounted() {
    //     if (this.alarmSocket != null) {
    //         this.alarmSocket.close();
    //     }
    // },
    mounted() {
        this.selectedIndex = this.options.indexOf(this.selectedOption);
        this.warningApiData(); // 알람 API
        this.infoApiData(); // 알림 API
        this.newInfoTotalApiData() // 새로운 알림 갯수
        this.newWarningTotalApiData() // 새로운 알람 갯수,

        this.fetchWebSocketAlarmData();

        this.fetchWebSocketNotiData();
        
    },
    methods: {
        // 모달
        messageCloseModal() {
            this.messageShowModal = false;
            this.messageContent = ''
            this.patientId = ''
            this.patientInfo = ''
        },
        // 메세지 보내기
        async messageSendModal(event) {
            event.preventDefault();
            this.messageShowModal = false;
            const apiUrl = `/api/web/notifications/message`;
            const requestData = {
                receiverId: this.patientId,
                smsSendYn: true,
                content: this.messageContent
            };
            if(this.patientId !== ''){
                if(this.patientId !== ''){
                    try {
                        const response = await fetch(apiUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(requestData)
                        });
                        if(response.status == 200) {
                            notify('메세지를 보냈습니다.');
                            this.messageContent = ''
                            this.patientId = ''
                            this.patientInfo = ''
                        } else {
                            errNotify('메세지 보내기를 실패하였습니다.');
                        }
                    } catch (error) {
                        console.error('Error fetching API data:', error);
                    }
                } else {
                    errNotify('메세지 내용을 입력해주세요.')
                }
            } else {
                errNotify('환자 정보를 입력해주세요.')
            }
        },
        alarmCloseModal() {
            this.alarmShowModal = false;
        },
        notiCloseModal() {
            this.notiShowModal = false;
        },

        updateSelectedIndex(index) {
            this.selectedIndex = index; // 선택된 옵션의 인덱스 업데이트
        },

        // 알람 리스트
        async warningApiData(type=null){
            // console.log('알람 리스트 조회');
            const alarmHeaderOffset = this.alarmHeaderOffset;
            const alarmHeaderLimit = this.alarmHeaderLimit;
            try {
                const response = await fetch(`/api/web/notifications/warning?offset=${alarmHeaderOffset}&limit=${alarmHeaderLimit}0&order=desc`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    const responseData = await response.json();
                    this.warningData = responseData;

                    // this.warningRowsData = this.warningData.rows
                    this.hasScrolledToBottom = false;
                    // this.warningRowsData = [...this.warningRowsData, ...responseData.rows];

                    if(type == '소켓') {
                        this.warningRowsData = this.warningData.rows
                        
                    } else {
                        this.warningRowsData = [...this.warningRowsData, ...responseData.rows];
                    }

                    // // 랜딩 페이지에 알람 리스트
                    // document.getElementsByClassName('wrapper-alarm')[0].getElementsByClassName('alarm-none')[0].remove();

                    // const date = document.createElement('span');
                    //     date.innerText = '';



                    // console.log('warningRowsData Modal',this.warningRowsData)
                }else if(response.status === 404) {
                    alert('해당 환자 정보가 존재하지 않습니다.');
                }
            } catch (error) {
                console.error('Error fetching API data:', error);
            }
        },

        checkScroll(event) {
            const scrollableDiv = event.target;
            const scrollPosition = scrollableDiv.scrollTop + scrollableDiv.clientHeight;
            const scrollHeight = scrollableDiv.scrollHeight;

            if (!this.hasScrolledToBottom && (scrollHeight - scrollPosition <= 50)) {
                this.hasScrolledToBottom = true;
                this.alarmHeaderOffset += 1;
                this.warningApiData();
            }
        },

        checkScrollInfo(event) {
            const scrollableDiv = event.target;
            const scrollPosition = scrollableDiv.scrollTop + scrollableDiv.clientHeight;
            const scrollHeight = scrollableDiv.scrollHeight;

            if (!this.hasScrolledToBottomInfo && (scrollHeight - scrollPosition <= 50)) {
                this.hasScrolledToBottomInfo = true;
                this.infoHeaderOffset += 1;
                this.infoApiData();
            }
        },

        // 알람 소켓
        fetchWebSocketAlarmData() {
            // 웹소켓을 통해 데이터 가져오기
            const userId = JSON.parse(sessionStorage.getItem('session')).userInfo.userId;
            // this.alarmSocket = new WebSocket(`wss://www.moree-guard.com/api/web/notifications/new/warning/${userId}`);
            this.alarmSocket = new WebSocket(`wss://www.moree-guard.com/api/web/notifications/new/warning/${userId}`);

            this.alarmSocket.onopen = () => {
                // console.log('알람 소켓 연결');
                const requestData = {};
                this.alarmSocket.send(JSON.stringify(requestData));
            };

            this.alarmSocket.onmessage = (event) => {
                // const message = JSON.parse(event.data);
                const message = event.data;
                // console.log('알람 소켓 메시지');
                console.log(message);

                // 알람이 발생한 경우 hasNewAlarms를 true로 설정
                this.hasNewAlarms = true;

                // 알람 리스트
                this.warningApiData('소켓');

                // 이벤트를 발생시켜 부모 컴포넌트에 알림 데이터 업데이트를 요청
                if (this.onUpdateAlarmData) {
                    this.onUpdateAlarmData();
                }
            };

            this.alarmSocket.onclose = () => {
                console.log('alarmSocket connection closed');
                this.alarmSocket = null;
            };

            this.alarmSocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
        },

        // 알림 소켓
        fetchWebSocketNotiData() {
            // 웹소켓을 통해 데이터 가져오기
            const userId = JSON.parse(sessionStorage.getItem('session')).userInfo.userId;
            this.notiSocket = new WebSocket(`wss://www.moree-guard.com/api/web/notifications/new/info/${userId}`);

            this.notiSocket.onopen = () => {
                // console.log('알림 소켓 연결');
                const requestData = {};
                this.notiSocket.send(JSON.stringify(requestData));
            };

            this.notiSocket.onmessage = (event) => {
                // const message = JSON.parse(event.data);
                const message = event.data;
                // console.log('알림 소켓 메시지');
                console.log(message);

                // 알림이 발생한 경우 hasNewNotifications true로 설정
                this.hasNewNotifications = true;

                // 알림 리스트
                this.infoApiData('소켓');

                // 이벤트를 발생시켜 부모 컴포넌트에 알림 데이터 업데이트를 요청
                if (this.onUpdateNotiData) {
                    this.onUpdateNotiData();
                }
            };

            this.notiSocket.onclose = () => {
                console.log('notiSocket connection closed');
                this.notiSocket = null;
            };

            this.notiSocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
        },

        // 알림 리스트
        async infoApiData(){
            const infoHeaderOffset = this.infoHeaderOffset;
            const infoHeaderLimit = this.infoHeaderLimit;

            try {
                const response = await fetch(`/api/web/notifications/info?offset=${infoHeaderOffset}&limit=${infoHeaderLimit}&order=desc`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    const responseData = await response.json();
                    this.infoData = responseData;

                    // this.infoRowsNotiData = this.infoData.rows

                    this.hasScrolledToBottomInfo = false;
                    // responseData.rows.forEach(newEntry => {
                    //     const existingEntry = this.infoRowsNotiData.find(entry => entry.receiveDate === newEntry.receiveDate);
                    //     if (existingEntry) {
                    //         // 날짜가 같은 항목이 있을 경우 notificationList에 새 항목 추가
                    //         existingEntry.notificationList.push(...newEntry.notificationList);
                    //     } else {
                    //         // 날짜가 같은 항목이 없을 경우 새 항목 추가
                    //         this.infoRowsNotiData.push({
                    //             receiveDate: newEntry.receiveDate,
                    //             notificationList: newEntry.notificationList
                    //         });
                    //     }
                    // });


                    // if(type == '소켓') {
                    //     this.infoRowsNotiData = [...this.infoRowsNotiData, ...responseData.rows];
                    //     // responseData.rows.forEach(newEntry => {
                    //     //     const existingEntry = this.infoRowsNotiData.find(entry => entry.receiveDate === newEntry.receiveDate);
                    //     //     if (existingEntry) {
                    //     //         existingEntry.notificationList.push(...newEntry.notificationList);
                    //     //     } else {
                    //     //         this.infoRowsNotiData.push({
                    //     //             receiveDate: newEntry.receiveDate,
                    //     //             notificationList: newEntry.notificationList
                    //     //         });
                    //     //     }
                    //     // });
                        
                    // } else {
                    //     responseData.rows.forEach(newEntry => {
                    //         const existingEntry = this.infoRowsNotiData.find(entry => entry.receiveDate === newEntry.receiveDate);
                    //         if (existingEntry) {
                    //             // 날짜가 같은 항목이 있을 경우 notificationList에 새 항목 추가
                    //             existingEntry.notificationList.push(...newEntry.notificationList);
                    //         } else {
                    //             // 날짜가 같은 항목이 없을 경우 새 항목 추가
                    //             this.infoRowsNotiData.push({
                    //                 receiveDate: newEntry.receiveDate,
                    //                 notificationList: newEntry.notificationList
                    //             });
                    //         }
                    //     });
                    // }

                    responseData.rows.forEach(newEntry => {
                        const existingEntry = this.infoRowsNotiData.find(entry => entry.receiveDate === newEntry.receiveDate);
                        if (existingEntry) {
                            // 날짜가 같은 항목이 있을 경우 notificationList에 새 항목 추가
                            existingEntry.notificationList.push(...newEntry.notificationList);
                        } else {
                            // 날짜가 같은 항목이 없을 경우 새 항목 추가
                            this.infoRowsNotiData.push({
                                receiveDate: newEntry.receiveDate,
                                notificationList: newEntry.notificationList
                            });
                        }
                    });


                    // console.log('infoRowsNotiData Modal', this.infoRowsNotiData)
                }else if(response.status === 404) {
                    alert('해당 환자 정보가 존재하지 않습니다.');
                }
            } catch (error) {
                console.error('Error fetching API data:', error);
            }
        },

        // 알람 안읽은 갯수
        async newWarningTotalApiData(){
            try {
                const response = await fetch(`/api/web/notifications/warning?offset=1&limit=10&order=desc&checkYn=false`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    const responseData = await response.json();
                    this.newWarningData = responseData;
                    this.newWarningTotalData = this.newWarningData.total

                    // console.log('newWarningTotalData', this.newWarningTotalData)
                }else if(response.status === 404) {
                    alert('해당 환자 정보가 존재하지 않습니다.');
                }
            } catch (error) {
                console.error('Error fetching API data:', error);
            }
        },

        // 알림 안읽은 갯수
        async newInfoTotalApiData(){
            try {
                const response = await fetch(`/api/web/notifications/info?offset=1&limit=10&order=desc&checkYn=false`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    const responseData = await response.json();
                    this.newInfoData = responseData;
                    this.newInfoTotalData = this.newInfoData.total
                }else if(response.status === 404) {
                    alert('해당 환자 정보가 존재하지 않습니다.');
                }
            } catch (error) {
                console.error('Error fetching API data:', error);
            }
        },

        // 알람 모두 읽기
        async allCheckWarningData(){
            try {
                const response = await fetch(`/api/web/notifications/check?warning_yn=false`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseData = await response.json();
                if(responseData === true) {
                    // this.$router.push({ path: `/patient/${senderId}` });
                    
                    // // 헤더에 빨간 라벨 제거
                    // if(document.getElementsByClassName('header-alarm')[0].classList.contains('header-dot')) {
                    //     document.getElementsByClassName('header-alarm')[0].classList.remove('header-dot');
                    // }
                    // 성공 처리 토스트
                    notify('알람 모두 읽기 처리하였습니다.');
                    const cardAlarms = document.getElementsByClassName('modal-container')[0].querySelectorAll('.card-alarm');
                    for(let i=0;i<cardAlarms.length;i++) {
                        const innerCardAlarm = cardAlarms[i].querySelector('.inner-card-alarm');
                        if (innerCardAlarm && !innerCardAlarm.classList.contains('inner-card-alarm-read')) {
                            innerCardAlarm.classList.add('inner-card-alarm-read');
                        }
                    }
                    
                    // console.log('alarmIdArr',alarmIdArr)
                } else {
                    alert('읽기 실패')
                }
            } catch (error) {
                console.error('Error fetching API data:', error);
            }
        },

        // 알림 모두 읽기
        async allCheckInfoData(){
            try {
                const response = await fetch(`/api/web/notifications/check?warning_yn=true`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    this.isNotiChecked = !this.isNotiChecked;

                    // // 헤더에 빨간 라벨 제거
                    // if(document.getElementsByClassName('header-noti')[0].classList.contains('header-dot')) {
                    //     document.getElementsByClassName('header-noti')[0].classList.remove('header-dot');
                    // }
                }

                if (!response.ok) {
                    // 4xx 에러 시
                    if (response.status >= 400 && response.status < 500) {
                        if(response.status == 404) {
                            console.log(`준비되지 않은 API 오류: ${response.status}`);
                        } else {
                            console.log(`클라이언트 오류: ${response.status}`);
                        }
                    } else {
                        console.log(`서버 오류: ${response.status}`);
                    }
                    throw new Error(`API 요청 실패: ${response.status}`);
                }
            } catch (error) {
                console.error('Error fetching API data:', error);
            }
        },

        // 메세지 보내기 환자 자동검색
        async autoSearch(event){
            const keyword = event.target.value;
            try {
                if(keyword.length !== 0 || event.keyCode === 'backspace'){
                    const response = await fetch(`/api/web/patients/search?keyword=${keyword}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    if (response.status === 200) {
                        const responseData = await response.json();
                        this.keywordList = responseData;
                        this.isSearch = true;
                    }else if(response.status === 422) {
                        this.isSearch = false
                    }else if(response.status === 404) {
                        alert('해당 환자 정보가 존재하지 않습니다.');
                    }
                }
                if(keyword.length === 0 || keyword.length === null || keyword === '' || this.keywordList.length === 0 || this.keywordList.length === null ) {
                    this.isSearch = false
                }
            } catch (error) {
                this.isSearch = false
                // TODO: 검색 결과 없을 시 오류 뱉음                 
                // console.error('Error fetching API data:', error);

            }
        },

        // 메세지 보내기 > input에 선택한 환자 정보 출력
        async getPatientInfo(event) {
            this.isSearch = false
            this.patientInfo = event.target.textContent
            this.patientId = event.currentTarget.closest("[data-patientId]").getAttribute("data-patientId");
        },
        
    },
    computed: {
        pageTitle() {
            const title = this.$route.meta.title || 'My App';
            return Array.isArray(title) ? title.join(', ').replace(',', '') : title;
        },
        isHomePage() {
            const currentPath = this.$route.path;
            return currentPath === '/' || currentPath === '/home';
        },
    },
    beforeUnmount() {
        if (this.alarmSocket) {
            this.alarmSocket.close();
        }

        if (this.notiSocket) {
            this.notiSocket.close();
        }
    },
    setup() {
        // 알람 및 알림 체크박스
        const isAlarmChecked = ref(false);
        const isNotiChecked = ref(false);
        const isSearch = ref(false);

        return {
            isAlarmChecked,
            isNotiChecked,
            isSearch
        }
    }
};
</script>

<template>
    <header>
        <div>
            <h2 v-if="isHomePage">
                <router-link v-for="(tab, index) in $route.meta.tabs" :key="index" :to="{ path: '', query: { tab: tab } }">
                    <span>{{ tab }}</span>
                </router-link>
            </h2>
            <h2 v-else class="page-title">{{ pageTitle }}</h2>
        </div>
        <div class="flex align-center">
            <div class="mr-20 flex header-icon cursor-pointer">
                <img :src="require('/src/assets/imgs/alarm_logo.png')" alt="알람 아이콘 이미지" @click="alarmShowModal = true">
                <!-- <span :class="['new-alarm', {'header-dot': newWarningTotalData}]"></span> -->
                <!-- <span 
                    :class="['new-alarm', { 'header-dot': socketAlarmData }]">
                </span> -->
                <!-- <span v-if="hasNewAlarms" class="new-alarm header-dot"></span> -->

                <!-- <span v-if="socketAlarmData" class="new-alarm header-alarm header-dot"></span>
                <span v-else class="new-alarm header-alarm"></span> -->
                <span v-if="hasNewAlarms" class="new-alarm header-alarm header-dot"></span>
            </div>
            <div class="mr-20 flex header-icon cursor-pointer">
                <img :src="require('/src/assets/imgs/noti_logo.png')" alt="알림 아이콘 이미지" @click="notiShowModal = true">
                <!-- <span :class="['new-alarm', {'header-dot': newInfoTotalData}]"></span> -->
                <span v-if="hasNewNotifications" class="new-alarm header-noti header-dot"></span>

            </div>
            <div>
                <ModalButtonComponent class="header-msg-btn bg-more-blue color-white" @click="messageShowModal = true;">
                    <img class="mr-10" :src="require('/src/assets/imgs/msg_logo.png')" alt="메시지 아이콘 이미지">
                    <span>메세지 보내기</span>
                </ModalButtonComponent>
            </div>
        </div>
    </header>

    <!--  메세지 모달 컴포넌트 -->
    <ModalComponent class="header-modal header-message-modal" :show-modal="messageShowModal" modal-title="변경이력모달">
        <template v-slot:header>
            <div class="modal-header">
                <div class="flex align-center">
                    <img :src="require('/src/assets/imgs/header_messge.png')" alt="환자상세변경이력이미지">
                    <b>메세지 보내기</b>
                </div>
                <img class="cursor-pointer" style="background-color: #fff; border-radius: 50%;" @click="messageCloseModal" :src="require('/src/assets/imgs/profile_close_icon.png')" alt="취소이미지">
            </div>
        </template>
        <template v-slot:body>
            <div>
                <!-- <div class="wrap-target flex">
                    <b>대상</b>
                    <div class="wrap-radio-btn">
                        <label class="radio-btn" v-for="(option, index) in options" :key="index" :style="{ color: index === selectedIndex ? '#0088FF' : '#888888' }">
                            <input type="radio" :value="option" v-model="selectedOption" @change="updateSelectedIndex(index)">
                            {{ option }}
                        </label>
                    </div>
                    
                </div> -->
                <div class="wrap-search">
                    <span class="wrap-search-icon"><i class="icon-search"></i></span>
                    <DefaultInputComponent class="wp-100" type="text" required :inputText="'이름 혹은 등록번호, 진료과를 입력해 주세요'" @keyup="autoSearch($event)" :value="patientInfo" />
                </div>
                <ul v-show="isSearch" :class="['searchList', {'keywordList': isSearch}]">
                    <li v-for="(item, index) in keywordList" :key="index" :data-code="item.patientCode" :data-patientId="item.id" @click="getPatientInfo($event)">{{ item.name }} ({{ item.gender }}, {{ item.age }}) {{ item.patientCode }}</li>
                </ul>
                <div class="message-content">
                    <div class="message-content-inner-header">
                        <b>메세지 내용</b>
                        <!-- <div>
                            <SquareCheckboxInputComponent v-model="isNotiChecked" label="문자메세지 같이 보내기" />
                        </div> -->
                    </div>
                    <textarea name="" id="" cols="30" rows="10" placeholder="메세지 내용 입력" v-model="messageContent"></textarea>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="wrap-footer-btn">
                <button class="" @click="messageSendModal">전송</button>
            </div>
        </template>
    </ModalComponent>

    <!-- 알람 컴포넌트 -->
    <ModalComponent class="header-modal header-alarm-modal" :show-modal="alarmShowModal" modal-title="변경이력모달" item="alarm" @scroll="checkScroll($event)">
        <template v-slot:header>
            <div class="modal-header">
                <div class="flex flex-between align-center">
                    <div>
                        <img class="mr-3" :src="require('/src/assets/imgs/alarm.png')" alt="알람 아이콘 이미지">
                        <b class="fs-24">알람</b>
                    </div>
                </div>
                <div class="flex align-center">
                    <CheckboxInputComponent style="margin-right: 10px;" v-model="isAlarmChecked" label="모두 읽음처리" @change="allCheckWarningData" />
                    <img class="cursor-pointer" style="background-color: #fff; border-radius: 50%;" @click="alarmCloseModal" :src="require('/src/assets/imgs/profile_close_icon.png')" alt="취소이미지">
                </div>
            </div>
        </template>
        <!-- <template v-slot:body> -->
        <template v-slot:body>
            <div v-if="warningData">
                <AlarmCardComponent isFix="all" v-for="(item, index) in warningRowsData" :key="index" :data="item" />
            </div>
            <div v-else>
                <div class="noti-none">
                    <span>알림이 없습니다.</span>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            
        </template>
    </ModalComponent>

    <!-- 알림 컴포넌트 -->
    <ModalComponent class="header-modal header-noti-modal" :show-modal="notiShowModal" modal-title="변경이력모달" item="noti" @scroll="checkScrollInfo($event)" @click="allCheckInfoData">
        <template v-slot:header>
            <div class="modal-header">
                <div class="flex flex-between align-center">
                    <div>
                        <img class="mr-3" :src="require('/src/assets/imgs/noti.png')" alt="알람 아이콘 이미지">
                        <b class="fs-24">알림</b>
                    </div>
                </div>
                <div class="flex align-center">
                    <CheckboxInputComponent v-model="isNotiChecked" label="모두 읽음처리" style="margin-right: 10px;"/>
                    <img class="cursor-pointer" style="background-color: #fff; border-radius: 50%;" @click="notiCloseModal" :src="require('/src/assets/imgs/profile_close_icon.png')" alt="취소이미지">
                </div>
            </div>
        </template>
        <template v-slot:body>
            <div v-if="infoData">
                <AlarmCardComponent elementClass="card-noti" isFix="fixed" v-for="(item, index) in infoRowsNotiData" :key="index" :data="item" />
            </div>
            <div v-else>
                <div class="noti-none">
                    <span>알람이 없습니다.</span>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            
        </template>
    </ModalComponent>
</template>
