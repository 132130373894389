<script>
export default {
    props: {
        inputText: {
            type: String,
            required: true,
        },
    }
};
</script>

<template>
    <input type="text" :placeholder=" inputText ">
</template>
  