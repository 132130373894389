<style>
/* nav */
nav.layout-nav {
    width: 160px;
    height: 100%;
    border-right: 1px solid #002244;
    position: fixed;
    left: 0;
    top: 0;
    font-size: 14px;
    padding: 30px 0 20px 0;
    color: #fff;
    background: linear-gradient(360deg, #002244 0%, #004488 100%);
    z-index: 100;
}
nav.layout-nav.active {
    width: 72px;
}
nav.layout-nav .wrap-menu-controll-arrow {
    display: ruby-text;
    padding-right: 12px;
    margin-bottom: 20px;
    text-align: right;
}
nav.layout-nav .menu-controll-arrow {
    /* margin-right: 12px; */
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    border-radius: 4px;
    padding: 9px 8px;
    width: 32px;
    height: 32px;
}
nav.layout-nav .menu-controll-arrow:hover {
    background-color: #00224480;
}
nav.layout-nav .profile-img {
    margin: 0 12px 20px 12px;
    border-radius: 8px;
    padding: 5px;
}
nav.layout-nav .wrap-profile {
    font-size: 12px;
    position: relative;
}
/* 프로필 상세 */
nav.layout-nav .profile-detail {
    position: absolute;
    top: 0;
    right: -448px;
    padding: 24px 24px 32px 24px;
    border-radius: 16px;
    width: 448px;
    border: 1px solid #DDD;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    color: #002244 !important;
}
nav.layout-nav .profile-detail .profile-detail-wrap-cancel-img {
    display: flex;
    justify-content: end;
}
nav.layout-nav .profile-detail .profile-detail-wrap-cancel-img img {
    cursor: pointer;
}
nav.layout-nav .profile-detail .profile-detail-img {
    width: 64px;
    height: 64px;
    margin-bottom: 20px;
}
.layout-main.active nav.layout-nav .profile-department {
    display: block;
}
.layout-main.active nav.layout-nav .profile-department {
    display: none;
}
nav.layout-nav .logout-btn {
    padding: 0 16px;
    background: #B22;
    border: 1px solid  #B22;
}
nav.layout-nav .logout-btn:hover {
    background-color: #882222;
    border: 1px solid #882222;
}
nav.layout-nav .pw-change-btn {
    cursor: pointer;
    padding: 8px 16px;
}
nav.layout-nav .pw-change-btn:hover {
    background-color: #0088FF1A;
    color: #2277BB;
    border-radius: 4px;
}
nav.layout-nav .pw-change-btn i {
    margin-right: -5px;
}
nav.layout-nav .phone-change-btn {
    padding: 0 8px;
}
nav.layout-nav .phone-change-btn:hover {
    background-color: #0088FF1A;
    color: #2277BB;
    border-radius: 4px;
}
nav.layout-nav .phone-change-btn:hover i {
    color: #0088FF;
}
nav.layout-nav ul {
    color: #888888;
    font-size: 14px;
}
nav.layout-nav ul li {
    display: flex;
    align-items: center;
    padding: 24px 17px 24px 17px;
}
.layout-main.active nav.layout-nav ul li {
    display: block;
    padding: 12px 13px 12px 13px;
    text-align: center;
}
nav.layout-nav ul li img {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 12px;
}
.layout-main.active nav.layout-nav ul li img {
    display: block;
    margin: auto;
    margin-bottom: 8px;
}
nav.layout-nav ul li span {
    letter-spacing: -1px;
}
nav.layout-nav ul a.router-link-active {
    color: #fff;
    background: linear-gradient(90deg, #0088FF 0%, #004488 100%);
}
nav.layout-nav .menu-btn:hover {
    background-color: #262f57;
}
nav.layout-nav .wrap-term-link {
    text-align: center;
    color: #0066BB;
}
nav.layout-nav .wrap-term-link a {
    font-weight: 500;
    text-decoration: underline;
}
nav.layout-nav .wrap-term-link a:first-child {
    margin-right: 24px;
}
nav.layout-nav .wrap-term-link a:hover {
    color: #0088FF;
}
/* 사이드메뉴 > 프로필 모달 > 연락처 변경 */
nav.layout-nav .phone-content .wrap-default-phone {
    display: flex;
    justify-content: space-between;
}
nav.layout-nav .phone-content .wrap-edit-phone {
    display: none;
}
nav.layout-nav .phone-content.edit-open .wrap-default-phone {
    display: none;
}
nav.layout-nav .phone-content.edit-open .wrap-edit-phone {
    display: block;
}
nav.layout-nav .phone-content .wrap-phone-certifi {
    display: none;
}
nav.layout-nav .phone-content.edit-open .wrap-phone-certifi {
    display: none;
}
nav.layout-nav .phone-content.certifi-open .wrap-phone-certifi {
    display: block;
}
nav.layout-nav .phone-content.certifi-open .wrap-edit-phone {
    display: none;
}
nav.layout-nav .phone-content.certifi-open .wrap-default-phone {
    display: none;
}
nav.layout-nav .wrap-edit-phone input,
nav.layout-nav .wrap-phone-certifi input {
    background-color: #fff;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #CCCCCC;
    padding: 0;
}
nav.layout-nav .wrap-phone-certifi input {
    width: 100%;
}
nav.layout-nav .wrap-edit-phone.entered input,
nav.layout-nav .wrap-phone-certifi.entered input,
nav.layout-nav .wrap-phone-certifi.certifi-entered input {
    border-bottom: 1px solid #0088FF;
}
nav.layout-nav .wrap-edit-phone input:focus,
nav.layout-nav .wrap-phone-certifi input:focus {
    outline: none;
}
nav.layout-nav .wrap-edit-phone .complete-btn {
    color: #888888;
    display: flex;
    align-items: center;
    cursor: pointer;
}
nav.layout-nav .wrap-edit-phone.entered .complete-btn {
    color: #0088FF;
}
nav.layout-nav .wrap-edit-phone .complete-btn .wrap-icon {
    display: block;
    margin-right: 8px;
}
nav.layout-nav .wrap-edit-phone .complete-btn img {
    width: 16px;
    height: 16px;
}
nav.layout-nav .wrap-certifi-input {
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
}
nav.layout-nav .wrap-certifi-input input {
    width: 100%;
}
nav.layout-nav .wrap-phone-certifi .time {
    font-size: 12px;
    color: #0066BB;
    margin-right: 4px;
    display: block;
}
nav.layout-nav .wrap-phone-certifi .certifi-check-btn {
    position: relative;
}
nav.layout-nav .wrap-phone-certifi .certifi-btn {
    margin-right: 8px;
}
nav.layout-nav .wrap-phone-certifi .certifi-btn.disabled button {
    cursor: no-drop;
    color: #888888;
    background-color: #EEEEEE;
    border: 1px solid #EEEEEE;
}
nav.layout-nav .wrap-phone-certifi button {
    background-color: #fff;
    color: #0088FF;
    border: 1px solid #0088FF;
    border-radius: 4px;
    font-size: 12px;
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
    font-weight: 700;
}
nav.layout-nav .wrap-phone-certifi .wrap-certifi-input button {
    position: absolute;
    top: 8px;
    right: 0;
    border: none;
    color: #888888;
    background-color: #EEEEEE;
    border: 1px solid #EEEEEE;
}
nav.layout-nav .wrap-phone-certifi.certifi-entered .wrap-certifi-input button {
    background-color: #fff;
    color: #0088FF;
    border: none;
}
nav.layout-nav .wrap-certifi-input .certifi-info {
    position: absolute;
    right: 0;
    bottom: -25px;
    font-size: 12px;
    color: #BB2222;
}
/* 비밀번호 변경 */
nav.layout-nav .wrap-pw-change {
    position: absolute;
    top: 0;
    right: -448px;
    padding: 24px 24px 32px 24px;
    border-radius: 16px;
    width: 448px;
    border: 1px solid #DDD;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    background-color: #fff;
    color: #002244 !important;
}
nav.layout-nav .wrap-pw-change .pw-change-header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}
nav.layout-nav .wrap-pw-change .pw-change-header img {
    margin-right: 16px;
}
nav.layout-nav .wrap-pw-change .pw-change-header h3 {
    font-size: 24px;
}
nav.layout-nav .wrap-pw-change .pw-change-content {
    text-align: left;
    margin-bottom: 64px;
}
nav.layout-nav .wrap-pw-change .pw-change-content > div {
    margin-bottom: 16px;
}
nav.layout-nav .wrap-pw-change .pw-change-content b {
    display: block;
    margin-bottom: 8px;
}
nav.layout-nav .wrap-pw-change .pw-change-content .wrap-pw-input {
    width: 100%;
    position: relative;
}
nav.layout-nav .wrap-pw-change .pw-change-content .wrap-pw-input input {
    width: 100%;
    border: 1px solid #CCCCCC;
    background-color: #E4E4E4;
    border-radius: 4px;
    padding: 0px 70px 0px 12px;
}
nav.layout-nav .wrap-pw-change .pw-change-content .wrap-pw-input input:focus {
    outline: none;
}
nav.layout-nav .wrap-pw-change .pw-change-content .wrap-pw-input.active input {
    border: 1px solid #0088FF;
    background-color: #fff;
}
nav.layout-nav .wrap-pw-change .pw-change-content .wrap-icon-cancel {
    position: absolute;
    color: #DD1111;
    top: 8px;
    right: 45px;
    cursor: pointer;
}
nav.layout-nav .wrap-pw-change .pw-change-content .wrap-pw-input.active .wrap-icon {
    border-radius: 4px;
}
nav.layout-nav .wrap-pw-change .pw-change-content .wrap-pw-input.active .wrap-icon:hover {
    background-color: #0088FF26;
    color: #0066BB;
}
nav.layout-nav .wrap-pw-change .pw-change-content .wrap-pw-input.error input {
    border: 1px solid #BB2222;
    background-color: #fff;
}
nav.layout-nav .wrap-pw-change .pw-change-content .wrap-pw-input input::placeholder {
    color: #BBBBBB;
}
nav.layout-nav .wrap-pw-change .pw-change-content .wrap-icon {
    position: absolute;
    color: #BBBBBB;
    font-size: 22px;
    top: 3px;
    right: 5px;
    cursor: pointer;
}
nav.layout-nav .wrap-pw-change .pw-change-content .pw-input-info {
    display: block;
    font-size: 12px;
    margin-left: 12px;
    color: #888888;
}
nav.layout-nav .wrap-pw-change .pw-change-footer {
    text-align: right;
}
nav.layout-nav .wrap-pw-change .pw-change-footer button {
    width: 120px;
    padding: 8px 0;
    font-weight: 700;
}
nav.layout-nav .wrap-pw-change .pw-change-footer button:hover {
    background-color: #2277BB;
    border: 1px solid #2277BB;
    color: #fff;
}
nav.layout-nav .wrap-pw-change .pw-change-footer .pw-change-btn.disabled {
    cursor: no-drop;
    background-color: #ddd;
    border: 1px solid #ddd;
    color: #888;
}
nav.layout-nav .wrap-pw-change .pw-change-footer .pw-change-btn.disabled:hover {
    background-color: #ddd;
    border: 1px solid #ddd;
    color: #888;
}
nav.layout-nav .wrap-pw-change .pw-change-footer button.cancel-btn {
    color: #BB2222;
    border: 1px solid #BB2222;
    background-color: #fff;
    margin-right: 8px;
}
nav.layout-nav .wrap-pw-change .pw-change-footer button.cancel-btn:hover {
    color: #882222;
    border: 1px solid #882222;
    background-color: #FFEEEE;
}
</style>

<script>
// import { ref  } from 'vue';
// import axios from 'axios';
import VueJwtDecode from 'vue-jwt-decode'
import {  notify } from '@/plugins/toastify';

export default {
    data() {
        return {
            // 메뉴 조회 API
            menus: [],
            routes: ['/', '/patient', '/consult', '/setting', '/user', '/reservation', '/device', '/noti'], // 각 route에 대한 정보 배열
            images: ['menu_home', 'menu_patient', 'menu_communition', 'menu_setting', 'menu_user', 'menu_reservation', 'menu_device', 'menu_noti'], // 각 이미지에 대한 정보 배열,

            // 프로필 모달 > 연락처 변경 > 연락처 입력란
            editPhoneNumber: '',
            isCertifiOpen: false,
            certifiNumber: '',
            isEditOpen: false,
            certifiEntered: false,
            isCertifiBtnDisabled: false,

            // 비밀번호 변경 > 비밀번호 인풋 가시성 상태
            isPasswordVisible: false,
            isPasswordCheckVisible: false,
            // 비밀번호 변경 > 비밀번호 인풋 입력란 활성화
            isActivePwInput: false,
            isActivePwCheckInput: false,

            sessionValue: null,
            sessionUserInfo: null,

            pwchangeMsg: '비밀번호가 변경 되었습니다.',

        };
    },
    mounted() {
        // 메뉴 조회 API
        this.currentDomain = window.location.pathname;
        this.fetchMenus();
    },
    computed: {
        // 입력 필드의 값이 0이 아닐 경우 true를 반환합니다.
        hasValue() {
            return this.editPhoneNumber.length > 0;
        }
    },
    methods: {
        // 메뉴 조회 API
        fetchMenus() {
            // TODO: API
            // axios.get('https://ca46debe-2938-43c8-a94c-f53d1fcbc566.mock.pstmn.io/menu')
            // .then(response => {
            //     this.menus = response.data.map((menu, index) => ({
            //         route: this.routes[index],
            //         activeImage: require(`@/assets/imgs/${this.images[index]}_active.png`),
            //         inactiveImage: require(`@/assets/imgs/${this.images[index]}.png`),
            //         name: menu.name,
            //         isActive: this.routes[index] === this.currentDomain, // 현재 도메인과 메뉴의 URL 비교
            //     }));
            // })
            // .catch(error => {
            //     console.error('Error fetching menus:', error);
            // });

            const response = {
                "data": [
                    {
                        "uuid": "BSDF24352DSGS",
                        "name": "홈보드",
                        "id": "1"
                    },
                    // {
                    //     "uuid": "BSDF24352DSGS",
                    //     "name": "사용자",
                    //     "id": "1"
                    // },
                    {
                        "uuid": "BSDF24352DSGS",
                        "name": "환자",
                        "id": "1"
                    },
                    // {
                    //     "uuid": "BSDF24352DSGS",
                    //     "name": "예약",
                    //     "id": "1"
                    // },
                    {
                        "uuid": "BSDF24352DSGS",
                        "name": "소통",
                        "id": "1"
                    },
                    // {
                    //     "uuid": "BSDF24352DSGS",
                    //     "name": "기기관리",
                    //     "id": "1"
                    // },
                    // {
                    //     "uuid": "BSDF24352DSGS",
                    //     "name": "공지사항",
                    //     "id": "1"
                    // },
                    {
                        "uuid": "BSDF24352DSGS",
                        "name": "설정",
                        "id": "1"
                    }
                ]
            };
            this.menus = response.data.map((menu, index) => ({
                route: this.routes[index],
                activeImage: require(`@/assets/imgs/${this.images[index]}_active.png`),
                inactiveImage: require(`@/assets/imgs/${this.images[index]}.png`),
                name: menu.name,
                isActive: this.routes[index] === this.currentDomain, // 현재 도메인과 메뉴의 URL 비교
            }));

        },
        toggleMenuActive(index) {
            this.menus.forEach((menu, i) => {
                menu.isActive = i === index;
            });
        },
        // 현재 url 에 patient 가 포함된 url 은 모두 환자 메뉴
        isPatientRoute(route) {
            const isCurrentRoutePatient = this.$route.path.includes('/patient');
            const isMenuRoutePatient = route.startsWith('/patient');
            
            return isCurrentRoutePatient && isMenuRoutePatient;
        },
        // 사이드 메뉴 컨트롤 함수
        toggleActive() {
            this.isNavActive = !this.isNavActive;
            // 이미지 변경
            const menuArrowImg = document.getElementById('menu-arrow-img');
            if (menuArrowImg) {
                menuArrowImg.src = this.isNavActive ? require('/src/assets/imgs/icon_direction_close.png') : require('/src/assets/imgs/icon_direction.png');
            }
            // 부모 컴포넌트에 이벤트를 발생시켜서 B.vue에서도 동시에 처리할 수 있도록 함
            this.$emit('toggleActive');
        },
        // 프로필 모달 > 연락처 변경 토글 (편집 UI/UX)
        toggleEditOpen() {
            this.isEditOpen = !this.isEditOpen;
        },
        // 프로필 모달 > 연락처 변경 토글 (편집 UI/UX)
        removeEditOpen() {
            this.isEditOpen = false;
            this.editPhoneNumber = '';
        },
        // 전화번호 입력 시 패턴 검사를 통해 숫자와 '-'만 입력 가능하도록 함
        formatPhoneNumber() {
            // 현재 입력된 값
            let value = this.editPhoneNumber;

            // 숫자와 '-'만 허용하는 정규식
            const phonePattern = /^[0-9-]*$/;

            // 정규식에 맞지 않는 값은 제거
            if (!phonePattern.test(value)) {
                this.editPhoneNumber = value.replace(/[^0-9-]/g, '');
                return;
            }

            // '-'를 자동으로 넣어주는 로직
            const cleanValue = value.replace(/-/g, ''); // '-' 제거한 숫자만 남긴 값
            const formattedValue = cleanValue
                .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
                .replace(/(\d{3})(\d{4})/, '$1-$2')
                .slice(0, 13); // 연락처 형식에 맞춰 3-4-4 또는 3-4-4 형식 적용

            // 포맷팅된 값 할당
            this.editPhoneNumber = formattedValue;
        },
        // 프로필 모달 > 연락처 변경 토글 (편집 UI/UX)
        toggleCertifiOpen() {
            // 'complete-btn' 클릭 시 입력된 전화번호를 'wrapDefaultPhone'에 설정
            if (this.$refs.wrapDefaultPhone && this.editPhoneNumber) {
                this.$refs.wrapDefaultPhone.textContent = this.editPhoneNumber;
            }

            this.isCertifiOpen = !this.isCertifiOpen;
            if (this.isCertifiOpen) {
                this.removeEditOpen();
            }
        },
        // 프로필 모달 > 연락처 변경 토글 (편집 UI/UX)
        removeCertifiOpen() {
            this.stopTimer();
            this.isCertifiBtnDisabled = false;
            this.$refs.timeDisplay.textContent = '5:00';
            // certifi-info 요소에 none 클래스가 없다면 추가
            const wrapPhoneCertifiElement = this.$refs.wrapPhoneCertifi;
            // `classList` API를 사용하여 `certifi-entered` 클래스를 제거
            if (wrapPhoneCertifiElement) {
                wrapPhoneCertifiElement.classList.remove('certifi-entered');
            }

            this.isCertifiOpen = false;
            this.certifiNumber = '';
        },
        // 프로필 모달 > 연락처 인증번호 재발송 input 입력 시
        updateCertifiEntered() {
           // 입력값을 통해 certifiEntered의 값을 업데이트
            this.certifiEntered = this.certifiNumber !== '';
            const certifiInfoText = this.$refs.certifiInfoText;
            // certifi-info 요소에 none 클래스가 없다면 추가
            if (certifiInfoText && !certifiInfoText.classList.contains('none')) {
                certifiInfoText.classList.add('none');
            }
        },
        // 프로필 모달 > 연락처 인증번호 재발송 UX/UI
        handleCertifiCheckBtnClick() {
            if (this.certifiEntered) {
                this.postCertifiCheckAPI();
            }
        },
        // 프로필 모달 > 연락처 인증번호 재발송 API
        async postCertifiSendAPI() {
            const apiUrl = `/api/web/phone-nmbr-certificate?phoneNmbr=`+document.getElementsByClassName('change-phonenum')[0].innerText;
            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    // body: JSON.stringify(requestData),
                });

                if (!response.ok) {
                    throw new Error(`API 요청 실패: ${response.status}`);
                }

                // 성공 시 처리 로직을 추가
                // const data = await response.json();
                //     console.log(data);
                // 인증번호 입력란 지우기
                this.certifiNumber = '';
                // time 에 남은 시간 추가
                this.isCertifiBtnDisabled = true;
                this.startTimer();

            } catch (error) {
                console.error('API 요청 중 에러 발생:', error);
            }
        },
        // 타이머 기능을 수행하는 함수
        startTimer() {
            // 초기 시간을 5분 (300초)로 설정합니다.
            let remainingTime = 300;
            
            // 타이머를 설정하고 timerId에 할당합니다.
            this.timerId = setInterval(() => {
                // 남은 시간을 1초씩 줄입니다.
                remainingTime--;

                // 시간 표시를 업데이트합니다.
                const minutes = Math.floor(remainingTime / 60);
                const seconds = remainingTime % 60;
                const timeText = `${minutes}:${seconds.toString().padStart(2, '0')}`;

                // timeDisplay 요소에 남은 시간을 업데이트합니다.
                this.$refs.timeDisplay.textContent = timeText;

                // 5분이 지나면 타이머를 멈추고 인증번호 재발송 버튼 비활성화 해제
                if (remainingTime <= 0) {
                    this.stopTimer();
                    this.isCertifiBtnDisabled = false;
                    this.$refs.timeDisplay.textContent = '5:00';
                }
            }, 1000);
        },
        // 타이머를 중지하는 함수
        stopTimer() {
            // timerId를 이용하여 타이머를 멈춥니다.
            if (this.timerId) {
                clearInterval(this.timerId);
            }
        },
        // 프로필 모달 > 연락처 인증번호 재발송 확인 API
        async postCertifiCheckAPI() {
            const changePhonenum = document.getElementsByClassName('change-phonenum')[0].innerText;
            const inputCode = document.getElementsByClassName('certifi-check-btn')[0].getElementsByTagName('input')[0].value;
            const apiUrl = `/api/web/phone-nmbr-certificate?phoneNmbr=`+changePhonenum+`&certificateCode=`+inputCode;
            
            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                // 응답 상태 코드가 성공 범위(200~299)에 해당하지 않으면 예외를 발생
                if (!response.ok) {
                    // 4xx 에러 시
                    if (response.status >= 400 && response.status < 500) {
                        if(response.status == 404) {
                            console.log(`준비되지 않은 API 오류: ${response.status}`);
                        } else {
                            console.log(`클라이언트 오류: ${response.status}`);
                        }
                        // TODO: 클라이언트 오류에 대한 UI/UX 추가 (예: 에러 토스트 표시)

                        if (this.$refs.certifiInfoText) {
                            this.$refs.certifiInfoText.classList.remove('none');
                        }
                    } else {
                        console.log(`서버 오류: ${response.status}`);
                        // TODO: 서버 오류에 대한 UI/UX 추가 (예: 에러 토스트 표시)

                    }
                    console.log('실패');
                    throw new Error(`API 요청 실패: ${response.status}`);
                }
                
                // 성공 시
                // 인증번호 재발송 UI/UX 복원
                this.stopTimer();
                this.isCertifiBtnDisabled = false;
                this.$refs.timeDisplay.textContent = '5:00';
                // certifi-info 요소에 none 클래스가 없다면 추가
                const wrapPhoneCertifiElement = this.$refs.wrapPhoneCertifi;
                // `classList` API를 사용하여 `certifi-entered` 클래스를 제거
                if (wrapPhoneCertifiElement) {
                    wrapPhoneCertifiElement.classList.remove('certifi-entered');
                }
                // 인증번호 재발송 UI/UX 닫기
                this.removeCertifiOpen();

                // 응답을 JSON 형식으로 파싱
                const data = await response.json();
                console.log(data);

                // 기본 연락처 변경
                const defaultPhoneSpan = this.$refs.wrapDefaultPhone;
                if (defaultPhoneSpan) {
                    defaultPhoneSpan.textContent = changePhonenum;
                }
                document.getElementsByClassName('wrap-default-phone')[0].getElementsByTagName('span')[0].innerText = changePhonenum;
                // 기본 연락처 UI/UX
                if (this.$refs.phoneContent) {
                    this.$refs.phoneContent.classList.remove('certifi-open');
                }

                // 연락처 변경 API
                this.patchDoctorPhonenum();
                // TODO: 세션에 변경된 연락처 업데이트
                
                
                // TODO: 성공 토스트


            } catch (error) {
                // API 요청 중에 에러가 발생하면 이를 처리합니다.
                console.error('API 요청 중 에러 발생:', error);
            }
        },

        // 연락처 변경 API
        async patchDoctorPhonenum() {
            const apiUrl = `/api/web/users/phone-nmbr`;
            const requestData = {
                "phoneNmbr": document.getElementsByClassName('change-phonenum')[0].innerText
            };
            try {
                const response = await fetch(apiUrl, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData),
                });

                // 응답 상태 코드가 성공 범위(200~299)에 해당하지 않으면 예외를 발생
                if (!response.ok) {
                    // 4xx 에러 시
                    if (response.status >= 400 && response.status < 500) {
                        if(response.status == 404) {
                            console.log(`준비되지 않은 API 오류: ${response.status}`);
                        } else {
                            console.log(`클라이언트 오류: ${response.status}`);
                        }
                        // TODO: 클라이언트 오류에 대한 UI/UX 추가 (예: 에러 토스트 표시)

                        
                    } else {
                        console.log(`서버 오류: ${response.status}`);
                        // TODO: 서버 오류에 대한 UI/UX 추가 (예: 에러 토스트 표시)

                    }
                    console.log('실패');
                    throw new Error(`API 요청 실패: ${response.status}`);
                }
                
                // 성공 시
                const data = await response.json();
                const decodeJWTtokenData = VueJwtDecode.decode(data);

                const updateSessionData = {
                    isLogin: true,
                    token: response.data,
                    userInfo: {
                        authenticationType: decodeJWTtokenData.authentication_type ?? '-',
                        departmentId: decodeJWTtokenData.department_id ?? '-',
                        departmentName: !decodeJWTtokenData.department_name ? '-':decodeURIComponent(escape(decodeJWTtokenData.department_name)),
                        organizationId: decodeJWTtokenData.organization_id ?? '-',
                        organizationName: !decodeJWTtokenData.organization_name ? '-':decodeURIComponent(escape(decodeJWTtokenData.organization_name)),
                        permissionType: decodeJWTtokenData.permission_type ?? '-',
                        userId: decodeJWTtokenData.user_id ?? '-',
                        userName: !decodeJWTtokenData.user_name ? '-': decodeURIComponent(escape(decodeJWTtokenData.user_name)),
                        job: !decodeJWTtokenData.job ? '-': decodeURIComponent(escape(decodeJWTtokenData.job)),
                        email: !decodeJWTtokenData.email ? '-': decodeURIComponent(escape(decodeJWTtokenData.email)),
                        phone: !decodeJWTtokenData.phone_nmbr ? '-': decodeURIComponent(escape(decodeJWTtokenData.phone_nmbr)),
                        chargeDepartments: !decodeJWTtokenData.charge_departments ? '-': decodeURIComponent(escape(decodeJWTtokenData.charge_departments))
                    },
                    // expiresAt: new Date(decodeJWTtokenData.exp * 1000)    // 밀리초로 변환
                    expiresAt: new Date(decodeJWTtokenData.exp * 1000)    // 밀리초로 변환
                };
                sessionStorage.setItem('session', JSON.stringify(updateSessionData));
                
                // TODO: 성공 토스트


            } catch (error) {
                // API 요청 중에 에러가 발생하면 이를 처리합니다.
                console.error('API 요청 중 에러 발생:', error);
            }
        },

        logout() {
            // 로그아웃 시 세션 스토리지에서 로그인 세션 제거
            sessionStorage.removeItem('session');
            sessionStorage.setItem('session', JSON.stringify({isLogin: false}));
            this.deleteCookie("token");
            // localStorage.removeItem('takeMedication');
            // localStorage.removeItem('newTakeMedicationitems');
            // localStorage.removeItem('patientInfo');
            localStorage.clear();

            // 로그아웃 후 로그인 페이지로 이동
            this.$router.push({ path: '/signin' });
        },
        
        // 쿠키 삭제
        deleteCookie(name) {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

            this.$router.push({ path: '/signin' });
        },

        // 사이드 메뉴 프로필 상세 여는 함수
        openProfileDetail() {
            const targetElement = this.$refs.targetElement;
            targetElement.classList.remove('none');
            targetElement.classList.add('block');
        },
        // 사이드 메뉴 프로필 상세 닫는 함수
        closeProfileDetail() {
            const targetElement = this.$refs.targetElement;
            targetElement.classList.remove('block');
            targetElement.classList.add('none');
        },
        // 사이드 메뉴 프로필 상세 > 비밀번호 변경 여는 함수
        openPwChange() {
            const pwChangeElement = this.$refs.pwChangeElement;
            pwChangeElement.classList.remove('none');
            pwChangeElement.classList.add('block');
            this.closeProfileDetail();
        },
        // 사이드 메뉴 프로필 상세 > 비밀번호 변경 닫는 함수
        closePwChange() {
            const pwChangeElement = this.$refs.pwChangeElement;
            pwChangeElement.classList.remove('block');
            pwChangeElement.classList.add('none');
            this.openProfileDetail();
        },
        // 사이드 메뉴 프로필 상세 > 비밀번호 변경에 인풋 type 변경
        togglePasswordVisibility() {
            // isPasswordVisible 상태를 토글
            this.isPasswordVisible = !this.isPasswordVisible;
        },
        togglePasswordCheckVisibility() {
            // isPasswordCheckVisible 상태를 토글
            this.isPasswordCheckVisible = !this.isPasswordCheckVisible;
        },
        // 사이드 메뉴 프로필 상세 > 비밀번호 변경에 인풋 활성화
        handleActivePwInput() {
            // 입력 값이 있을 때 active 클래스를 추가
            this.isActivePwInput = this.$refs.passwordInput.value !== '';
            // wrap-icon-cancel 요소의 none 클래스를 토글
            const passwordInputValue = this.$refs.passwordInput.value;
            const wrapIconElement = this.$refs.pwWrapCancelIcon;
            if (passwordInputValue) {
                wrapIconElement.classList.remove('none');
            } else {
                wrapIconElement.classList.add('none');
            }
           // 입력된 비밀번호가 유효하지 않으면 error 클래스를 추가
            const isValid = this.validatePassword(passwordInputValue);
            // wrap-pw-input 엘리먼트에 error 클래스를 토글
            this.$refs.passwordInput.parentNode.classList.toggle('error', !isValid);
            // 변경완료 버튼에 disabled 클래스를 토글
            this.$refs.pwChangeBtn.classList.toggle('disabled', !isValid);
            // ** disabled 클래스가 없는 경우 putChangePasswordAPI 함수를 추가
            if (!this.$refs.pwChangeBtn.classList.contains('disabled')) {
                this.$refs.pwChangeBtn.addEventListener('click', this.putChangePasswordAPI);
            } else {
                this.$refs.pwChangeBtn.removeEventListener('click', this.putChangePasswordAPI);
            }
             // ** wrap-pw-input 두 엘리먼트의 error 클래스 존재 여부 확인
            const pwInputHasError = this.$refs.passwordInput.parentNode.classList.contains('error');
            const pwCheckInputHasError = this.$refs.passwordCheckInput.parentNode.classList.contains('error');
            
            // ** 두 엘리먼트 중 하나라도 error 클래스가 있으면 변경완료 버튼에 disabled 클래스를 추가
            if (pwInputHasError || pwCheckInputHasError) {
                this.$refs.pwChangeBtn.classList.add('disabled');
            } else {
                // ** 두 엘리먼트 모두 error 클래스가 없으면 변경완료 버튼에서 disabled 클래스를 제거
                this.$refs.pwChangeBtn.classList.remove('disabled');
            }
        },
        // 비밀번호가 영문과 숫자 조합 6자리 이상인지 검증
        validatePassword(password) {
            const isAlphaNumeric = /^[a-zA-Z0-9]+$/.test(password);
            const hasLetter = /[a-zA-Z]/.test(password);
            const hasNumber = /[0-9]/.test(password);
            return password.length >= 6 && hasLetter && hasNumber && isAlphaNumeric;
        },
        handleActivePwCheckInput() {
            // 입력 값이 있을 때 active 클래스를 추가
            this.isActivePwCheckInput = this.$refs.passwordCheckInput.value !== '';
            // wrap-icon-cancel 요소의 none 클래스를 토글
            const passwordCheckInputValue = this.$refs.passwordCheckInput.value;
            const wrapIconElement = this.$refs.pwCheckWrapCancelIcon;
            if (passwordCheckInputValue) {
                wrapIconElement.classList.remove('none');
            } else {
                wrapIconElement.classList.add('none');
            }
            // 새 비밀번호와 비밀번호 확인 입력 필드의 값 비교
            const passwordInputValue = this.$refs.passwordInput.value;
            const isMatch = passwordCheckInputValue === passwordInputValue;
            // passwordCheckInput의 parentNode에 error 클래스를 토글
            this.$refs.passwordCheckInput.parentNode.classList.toggle('error', !isMatch);
            this.$refs.pwChangeBtn.classList.toggle('disabled', !isMatch);
            // ** disabled 클래스가 없는 경우 putChangePasswordAPI 함수를 추가
            if (!this.$refs.pwChangeBtn.classList.contains('disabled')) {
                this.$refs.pwChangeBtn.addEventListener('click', this.putChangePasswordAPI);
            } else {
                this.$refs.pwChangeBtn.removeEventListener('click', this.putChangePasswordAPI);
            }
            // ** wrap-pw-input 두 엘리먼트의 error 클래스 존재 여부 확인
            const pwInputHasError = this.$refs.passwordInput.parentNode.classList.contains('error');
            const pwCheckInputHasError = this.$refs.passwordCheckInput.parentNode.classList.contains('error');
            
            // ** 두 엘리먼트 중 하나라도 error 클래스가 있으면 변경완료 버튼에 disabled 클래스를 추가
            if (pwInputHasError || pwCheckInputHasError) {
                this.$refs.pwChangeBtn.classList.add('disabled');
            } else {
                // ** 두 엘리먼트 모두 error 클래스가 없으면 변경완료 버튼에서 disabled 클래스를 제거
                this.$refs.pwChangeBtn.classList.remove('disabled');
            }
        },
        // 사이드 메뉴 프로필 상세 > 비밀번호 변경에 인풋 x 버튼 함수
        clearPwInputValue() {
            this.$refs.passwordInput.value = '';
            this.$refs.pwWrapCancelIcon.classList.add('none');
            this.isActivePwInput = false;
        },
        clearPwCheckInputValue() {
            this.$refs.passwordCheckInput.value = '';
            this.$refs.pwCheckWrapCancelIcon.classList.add('none');
            this.isActivePwCheckInput = false;
        },

        // 비밀번호 변경 API
        async putChangePasswordAPI() {
            // TODO: api
            const requestData = {
                "access_pw": this.$refs.passwordCheckInput.value,
            };
            try {
                const response = await fetch('/api/web/users/access-pw', {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData),
                });

                if (!response.ok) {
                    // 4xx 에러 시
                    if (response.status >= 400 && response.status < 500) {
                        if(response.status == 404) {
                            console.log(`준비되지 않은 API 오류: ${response.status}`);
                        } else {
                            console.log(`클라이언트 오류: ${response.status}`);
                        }
                        
                        // TODO: 클라이언트 오류에 대한 UI/UX 추가 (예: 에러 토스트 표시)
                        
                       
                    } else {
                        console.log(`서버 오류: ${response.status}`);
                        // TODO: 서버 오류에 대한 UI/UX 추가 (예: 에러 토스트 표시)

                    }
                    console.log('실패');
                    throw new Error(`API 요청 실패: ${response.status}`);
                }
                console.log('성공');
                // 성공 시, 로그아웃
                this.logout();
                // toastify
                notify(this.pwchangeMsg)

                // 응답 데이터를 JSON 형식으로 파싱합니다.
                const data = await response.json();
                console.log(data);
                // 응답 데이터에 따라 추가 로직을 처리하십시오.
            } catch (error) {
                // API 호출 중 에러가 발생하면 에러를 처리합니다.
                console.error('비밀번호 변경 API 요청 중 에러 발생:', error);
            }
        },
    },
    setup() {
        // 핸드폰 번호 형식 함수 params 01012345678 return 010-1234-5678
        const formatPhoneNum = (phoneNumber) => {
            const regex = /(\d{3})(\d{4})(\d{4})/;
            const formattedNumber = phoneNumber.replace(regex, '$1-$2-$3');
            return formattedNumber;
        }

        return {
            formatPhoneNum
        }
    },
    created() {
        // 세션에서 값 가져오기
        this.sessionValue = JSON.parse(sessionStorage.getItem('session'));
        this.sessionUserInfo = null;
        console.log(this.sessionValue.isLogin);
        if(this.sessionValue.isLogin == true) {
            this.sessionUserInfo = this.sessionValue.userInfo;
            this.sessionUserInfo.phone = this.formatPhoneNum(this.sessionUserInfo.phone);

            // TODO: 연락처 변경 시, 세션에 변경된 연락처 업데이터 하기

        }
        
    }
};
</script>

<template>
    <nav :class="['layout-nav', { 'active': isNavActive }]">
        <!-- 임상 시 주석처리
        <div class="wrap-menu-controll-arrow">
            <span class="menu-controll-arrow block cursor-pointer" @click="toggleActive">
                <img id="menu-arrow-img" :src="require('/src/assets/imgs/icon_direction.png')" alt="메뉴 상단 열림 화살표 이미지">
            </span>
        </div> -->
        <div class="profile-img text-center bg-white">
            <img class="pin-img" :src="require('/src/assets/imgs/logo.png')" alt="로고 이미지">
        </div>
        <div class="wrap-profile text-center mb-20">
            <div class="m-auto mb-3 cursor-pointer" style="width:40px;height:40px;" @click="openProfileDetail">
                <img v-if="this.sessionUserInfo.job == '의사'" class="wp-100" :src="require('/src/assets/imgs/profile_doctor.png')" alt="프로필 의사 이미지">
                <img v-else class="wp-100" :src="require('/src/assets/imgs/profile_nurse.png')" alt="프로필 간호사 이미지">
            </div>
            <!-- 세션값 받아서 정보 넣기 -->
            <b class="block cursor-pointer" style="margin-bottom: 4px;" @click="openProfileDetail">{{ this.sessionUserInfo.userName ?? '-' }}</b>
            <div class="profile-department">
                <span class="block cursor-pointer" style="margin-bottom: 4px;" @click="openProfileDetail">{{ this.sessionUserInfo.job ?? '-' }}</span>
                <span class="block cursor-pointer" @click="openProfileDetail">{{ this.sessionUserInfo.departmentName ?? '-' }}</span>
            </div>

            <!-- 프로필 상세 -->
            <div class="profile-detail none bg-white" ref="targetElement">
                <div class="profile-detail-wrap-cancel-img">
                    <img class="cursor-pointer" :src="require('/src/assets/imgs/profile_close_icon.png')" @click="closeProfileDetail" alt="프로필 취소 아이콘 이미지">
                </div>
                <div>
                    <!-- <img class="profile-detail-img" :src="require('/src/assets/imgs/profile_nurse.png')" alt="프로필 간호사 이미지"> -->
                    <img v-if="this.sessionUserInfo.job == '의사'" class="wp-100 profile-detail-img" :src="require('/src/assets/imgs/profile_doctor.png')" alt="프로필 의사 이미지">
                    <img v-else class="wp-100 profile-detail-img" :src="require('/src/assets/imgs/profile_nurse.png')" alt="프로필 간호사 이미지">
                    <b class="block fs-24">{{ this.sessionUserInfo.userName ?? '-' }}</b>
                    <span class="block">{{ this.sessionUserInfo.job ?? '-' }}</span>
                    <span class="block" style="margin-bottom: 16px;">{{ this.sessionUserInfo.organizationName ?? '-' }}. {{ this.sessionUserInfo.departmentName ?? '-' }}</span>
                    <div style="margin-bottom: 32px;">
                        <b class="pw-change-btn color-point-skyblue" @click="openPwChange">비밀번호 변경 <i class="icon-right-open"></i></b>
                    </div>
                </div>
                <div style="margin-bottom: 32px;">
                    <button class="logout-btn" @click="logout">로그아웃</button>
                </div>
                <div class="text-left">
                    <div class="flex" style="margin-bottom: 8px;">
                        <b class="wp-30">이메일</b>
                        <span>{{ this.sessionUserInfo.email ?? '-' }}</span>
                    </div>
                    <div class="flex" style="margin-bottom: 32px;">
                        <b class="wp-30">연락처</b>
                        <div ref="phoneContent" :class="['wp-70 phone-content', { 'edit-open': isEditOpen, 'certifi-open': isCertifiOpen }]">
                            <!-- 디폴트 -->
                            <div class="wrap-default-phone">
                                <span>{{ this.sessionUserInfo.phone ?? '-' }}</span>
                                <b class="phone-change-btn cursor-pointer color-point-skyblue" @click="toggleEditOpen"><i class="icon-pencil"></i> 변경</b>
                            </div>
                            <!-- 연락처 편집 입력 -->
                            <div :class="['wrap-edit-phone', { entered: hasValue }]">
                                <div class="flex flex-between align-center">
                                    <input type="text" v-model="editPhoneNumber" placeholder=" 전화번호 입력" @input="formatPhoneNumber">
                                    <div class="complete-btn" @click="hasValue && toggleCertifiOpen()">
                                        <span class="wrap-icon"><i class="icon-ok-circle"></i></span>
                                        <b>완료</b>
                                    </div>
                                    <img class="cursor-pointer" :src="require('/src/assets/imgs/profile_phone_edit_cancel.png')" alt="연락처 변경 삭제 아이콘" @click="removeEditOpen">
                                </div>
                            </div>
                            <!-- 인증번호 재발송 -->
                            <div :class="['wrap-phone-certifi', { 'certifi-entered': certifiEntered }]" ref="wrapPhoneCertifi">
                                <div class="flex flex-between align-center" style="margin-bottom: 8px;">
                                    <span class="change-phonenum" ref="wrapDefaultPhone">010-1234-1234</span>
                                    <div class="flex align-center">
                                        <div :class="['certifi-btn', { 'disabled': isCertifiBtnDisabled }]">
                                            <button @click="postCertifiSendAPI" :disabled="isCertifiBtnDisabled">인증번호 재발송</button>
                                        </div>
                                        <img class="cursor-pointer" :src="require('/src/assets/imgs/profile_phone_edit_cancel.png')" alt="연락처 변경 삭제 아이콘" @click="removeCertifiOpen">
                                    </div>
                                </div>
                                <div class="wrap-certifi-input">
                                    <span ref="timeDisplay" class="time">5:00</span>
                                    <div class="certifi-check-btn">
                                        <input type="text" v-model="certifiNumber" placeholder="인증번호 입력" @input="updateCertifiEntered">
                                        <button @click="isCertifiBtnDisabled && handleCertifiCheckBtnClick()">확인</button>
                                    </div>
                                    <span ref="certifiInfoText" class="certifi-info none">인증번호가 올바르지 않습니다.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex" style="margin-bottom: 32px;">
                        <b class="wp-30">담당 진료과</b>
                        <div style="line-height: 24px;">
                            <div v-if="!this.sessionUserInfo.chargeDepartments || this.sessionUserInfo.chargeDepartments=='undefined' || this.sessionUserInfo.chargeDepartments == '-'">
                                <span>-</span>
                            </div>
                            <div v-else>
                                <span v-for="(department, index) in this.sessionUserInfo.chargeDepartments.split(',')" :key="index" class="block">{{ department }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="wrap-term-link">
                        <a href="#">서비스 이용 약관</a>
                        <a href="#">개인정보 처리방침</a>
                    </div>
                </div>
            </div>
            <!-- 비밀번호 변경 -->
            <div class="wrap-pw-change none" ref="pwChangeElement">
                <div class="pw-change-header">
                    <img :src="require('/src/assets/imgs/profile_pw_change.png')" alt="프로필 간호사 이미지">
                    <h3>비밀번호 변경</h3>
                </div>
                <div class="pw-change-content">
                    <div>
                        <b>새로 등록할 비밀번호</b>
                        <div :class="['wrap-pw-input', { active: isActivePwInput }]">
                            <input v-bind:type="isPasswordVisible ? 'text' : 'password'" ref="passwordInput" @input="handleActivePwInput" placeholder="영문, 숫자 조합 6자리 이상">
                            <span class="wrap-icon-cancel none" ref="pwWrapCancelIcon" @click="clearPwInputValue"><i class="icon-cancel"></i></span>
                            <span class="wrap-icon" @click="togglePasswordVisibility"><i :class="isPasswordVisible ? 'icon-eye' : 'icon-eye-off'"></i></span>
                            <span class="pw-input-info">영문, 숫자 조합 6자리 이상</span>
                        </div>
                    </div>
                    <div>
                        <b>새 비밀번호 확인</b>
                        <div :class="['wrap-pw-input', { active: isActivePwCheckInput }]">
                            <input v-bind:type="isPasswordCheckVisible ? 'text' : 'password'" ref="passwordCheckInput" @input="handleActivePwCheckInput" placeholder="영문, 숫자 조합 6자리 이상">
                            <span class="wrap-icon-cancel none" ref="pwCheckWrapCancelIcon" @click="clearPwCheckInputValue"><i class="icon-cancel"></i></span>
                            <span class="wrap-icon" @click="togglePasswordCheckVisibility"><i :class="isPasswordCheckVisible ? 'icon-eye' : 'icon-eye-off'"></i></span>
                            <span class="pw-input-info">영문, 숫자 조합 6자리 이상</span>
                        </div>
                    </div>
                </div>
                <div class="pw-change-footer">
                    <button class="cancel-btn" @click="closePwChange">취소</button>
                    <!-- <button class="pw-change-btn disabled" ref="pwChangeBtn">변경완료</button> -->
                    <!-- <button class="pw-change-btn disabled" :class="{ disabled: isDisabled }" ref="pwChangeBtn" @click="putChangePasswordAPI">변경완료</button> -->
                    <button class="pw-change-btn disabled" :class="{ disabled: isDisabled }" ref="pwChangeBtn">변경완료</button>
                </div>
            </div>

        </div>
        <ul>
            <router-link
                v-for="(menu, index) in menus"
                :key="index"
                class="btn menu-btn block"
                :to="menu.route"
                :class="{ 'router-link-active': isPatientRoute(menu.route) }"
                @click="toggleMenuActive(index)"
            >
                <li>
                    <img v-if="this.$route.path.includes('/patient')" :src="isPatientRoute(menu.route) ? menu.activeImage : menu.inactiveImage">
                    <img v-else :src="menu.isActive ? menu.activeImage : menu.inactiveImage" alt="메뉴 이미지">
                    <span>{{ menu.name }}</span>
                </li>
            </router-link>
        </ul>
    </nav>
</template>
