<template>
    <button @click="openModal">
      <slot></slot>
    </button>
</template>
  
  <script>
  export default {
    methods: {
      openModal() {
        this.$emit('open');
      },
    },
  };
  </script>
  
  <style scoped>
  /* 모달 버튼 스타일 */
  button {
    padding: 10px 20px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  </style>
  