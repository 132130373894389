import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export const notify = (msg) => {
  toast(msg, {
    "type": "success",
    "position": "top-center",
    "autoClose": 2000,
    "transition": "flip",
    "pauseOnHover": false,
    "hideProgressBar": true,
    // "pauseOnHover": false,
  }); 
}

export const errNotify = (msg) => {
  toast(msg, {
    "type": "error",
    "position": "top-center",
    "autoClose": 2000,
    "transition": "flip",
    "hideProgressBar": true,
    // "pauseOnHover": false,
  }); 
}