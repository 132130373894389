<template>
    <div class="flex hp-100">
        <Nav></Nav>
        <div class="inner-contents wp-100">
            <Header></Header>
            <router-view></router-view>
            <main>
                <table-component></table-component>
            </main>
            <Footer></Footer>
        </div>
        
    </div>
</template>

<script>
import Header from '../layouts/DashboardLayout/HeaderLayout.vue';
import Footer from '../layouts/DashboardLayout/FooterLayout.vue';
import Nav from '../layouts/DashboardLayout/NavbarLayout.vue';
import TableComponent from '../components/table/tableComponent.vue';

export default ({
    name: 'LandingView',
    components: {
        Header,
        Footer,
        Nav,
        TableComponent,
    },
    methods: {
        // 탭 이동 함수
        tabSelect: function(e) {
            var tabname = e.target.getAttribute('data-tabname');

            var tab_content = document.getElementsByClassName('tab-wrap')[0].getElementsByClassName('tab-content');
            for(var i=tab_content.length-1;i>-1;i--){

                var target_tabname = tab_content[i].getAttribute('data-tabname');
                tab_content[i].style.display = ( target_tabname == tabname )?'block':'none';
            }

            var tabs = e.target.parentNode.parentNode.getElementsByClassName('tab-controller');
            for(var j = 0;j<tabs.length; j++)
                tabs[j].className = tabs[j].className.replace("select", "");
                if(e.target.get) {
                    e.target.className += ' select';
                }
            
            e.target.className += ' select';
        }
    }
})

</script>
