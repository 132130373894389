<style scoped>
  /* 모달 스타일 */
/* .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content {
    background: white;
    box-shadow: 0px 4px 4px 0px #00000040;
    border: 1px solid #ddd;
    padding: 24px;
    border-radius: 16px;
} */


/* 모달이 나타날 때 애니메이션 설정 */
.modal-fade-enter-active, .modal-fade-leave-active {
    transition: opacity 0.2s;
}
.modal-fade-enter, .modal-fade-leave-to {
    opacity: 0;
}
/* 모달 스타일 */
.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.modal-wrapper {
    width: 50%;
    height: 80%;
}
.modal-header {
    height: 50px;
}
.modal-body {
    height: calc(100% - 50px);
    padding-right: 10px;
}
.modal-container {
    height: 100%;
    background: #eee;
    box-shadow: 0px 4px 4px 0px #00000040;
    border: 1px solid #ddd;
    padding: 24px;
    border-radius: 16px;
    overflow: hidden;
}
/* custom */
.patient-create-viewpoint-modal .modal-wrapper {
    width: 400px;
    height: 500px;
}
.patient-create-viewpoint-modal .modal-container,
.patient-create-symptom-modal .modal-container {
    background-color: #fff;
}
.patient-create-viewpoint-modal .modal-body {
    height: 350px;
    padding-right: 0;
}
.patient-create-symptom-modal .modal-wrapper {
    width: 640px;
    height: 592px;
}
.footer-call-modal .modal-wrapper {
    width: 480px;
    height: auto;
}
.patient-create-symptom-modal .modal-body {
    height: 452px;
}
.header-modal .modal-container {
    background-color: #fff;
}
.header-message-modal .modal-wrapper {
    width: 640px;
    height: auto;
}
.header-message-modal .modal-body {
    position: relative;
    height: 420px;
    padding-right: 0;
}
.header-message-modal .modal-container {
    height: auto;
}
.header-alarm-modal .modal-body,
.header-noti-modal .modal-body {
    height: 655px;
}
.header-alarm-modal .modal-wrapper,
.header-noti-modal .modal-wrapper {
    width: 400px;
}
.patient-create-check-modal .modal-wrapper {
    width: 742px;
}
.patient-create-check-modal .modal-container {
    padding: 24px 24px 32px 24px;
}
.patient-create-check-modal .modal-body {
    height: calc(100% - 122px);
}
.patient-create-treament-search-modal .modal-wrapper {
    width: 960px;
    height: auto;
}
.patient-create-treament-search-modal .modal-container {
    background-color: #fff;
    height: auto;
}
.patient-create-treament-search-modal .modal-header {
    height: auto;
    margin-bottom: 24px;
}
.patient-create-treament-search-modal .modal-body {
    padding-right: 0;
    height: auto;
}

.patient-create-take-medication-modal .modal-container {
    background-color: #fff;
}
.patient-create-take-medication-modal .modal-wrapper {
    width: 480px;
    height: auto;
}
.patient-create-take-medication-modal .modal-body {
    min-height: 255px;
    max-height: 608px;
}
.patient-detail-history-modal .modal-wrapper {
    width: 742px;
    /* height: auto; */
    min-height: 360px;
    max-height: 800px;
}
.patient-detail-history-modal .modal-wrapper .modal-container {
    /* height: auto; */
}
.header-memo-modal .modal-wrapper {
    width: 480px;
    height: 352px;
}
.header-memo-modal .modal-body {
    background-color: #EEEEEE;
    width: 432px;
    height: 160px;
    padding: 16px;
    border-radius: 4px;
    text-align: left;
    margin-top: 10px;
}
.header-memo-modal .modal-footer {
    display: flex;
    justify-content: end;
    margin-top: 32px;
}
</style>

<script>
export default {
    props: {
        showModal: {
            type: Boolean,
            required: true
        },
        modalTitle: {
            type: String,
            default: "Modal Title"
        },
        item: {
            type: String,
            required: false
        }
    },
    methods: {
        handleScroll(event) {
            // 이벤트를 부모 컴포넌트로 전파
            this.$emit('scroll', event);
        }
    }
};
</script>

<template>
    <!-- 모달이 열릴 때만 컨텐츠가 보이도록 설정 -->
    <transition name="modal-fade">
        <div class="modal-mask" v-if="showModal">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <!-- 헤더 영역 -->
                    <div class="modal-header">
                        <slot name="header">
                            <h3 class="modal-title">{{ modalTitle }}</h3>
                        </slot>
                    </div>
                    <!-- 내용 영역 -->
                    <!-- <div class="modal-body scrollbar"> -->
                    <template v-if="item && item === 'alarm'">
                        <div class="modal-body scrollbar" @scroll="handleScroll">
                            <slot name="body"></slot>
                        </div>
                    </template>
                    <template v-else-if="item && item === 'noti'">
                        <div class="modal-body scrollbar" @scroll="handleScroll">
                            <slot name="body"></slot>
                        </div>
                    </template>
                    <template v-else>
                        <div class="modal-body scrollbar">
                            <slot name="body"></slot>
                        </div>
                    </template>
                    
                    <!-- 푸터(버튼) 영역 -->
                    <div class="modal-footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
